import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }
}

export const API_URL = 'http://apitickets.k2asoft.com';

