import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/tickets/list/0',
    title: 'Panel',
    icon: 'mdi mdi-gauge',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/users/list',
    title: 'Usuarios',
    icon: 'mdi mdi-account',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/pagos/list',
    title: 'Medios de pago',
    icon: 'mdi mdi-cash-multiple',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/proyectos/list',
    title: 'Proyectos',
    icon: 'mdi mdi-clipboard',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [],
  },
  {
    path: '/tickets/list/0',
    title: 'Tickets',
    icon: 'mdi mdi-arrange-send-backward',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: [
      {
        path: '/tickets/list/0',
        title: 'Sin Validar',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tickets/list/1',
        title: 'Validados',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/tickets/validate/',
        title: 'Validar Tickets',
        icon: '',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
      }
      // {
      //   path: '/tickets/stats/',
      //   title: 'Estadísticas',
      //   icon: '',
      //   class: '',
      //   label: '',
      //   labelClass: '',
      //   extralink: false,
      //   submenu: []
      // }
    ]
  }
];
